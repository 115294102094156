import { CatalogsList } from 'widgets/Catalogs/CatalogsList';

const catalogs: any = [
  {
    id: 1,
    quantity: 8550,
    price: 800000,
    priceDemo: 0,
    priceSubscription: 0,
    classname: 'dusty_rose',
    order: 1,
    preOrder: false,
    lastUpdate: '2023-12-22T11:32:27.181Z',
    countQuestionnaires: 1,
    questionnaires: [
      {
        id: 6,
        avatars: [
          {
            id: 10,
            file: {
              preview:
                'dd25a9ad-34db-44b1-a116-7c0c2777a43c-e3645f77-4b68-47b4-b4a9-bc146842dcbb.jpg',
            },
          },
        ],
      },
    ],
    title: {
      ru: 'Клуб Первых',
      en: 'Club of First ',
      de: '',
    },
    subtitle: {
      ru: '+ Бонусный доступ во все наши каталоги',
      en: '+ Bonus access to all our catalogs',
      de: '',
    },
    description: {
      ru: '- Бонусный доступ во все наши каталоги: VIP/Exclusive/UAE/Turkey/ALL WEST\n- 8550 анкет (без повторов)\n- Более 70 медийных девочек и звезд: актрисы/певицы/модели/блогерши/спортсменки/порноактрисы.\n- Ежедневное пополнение каталога.\n- Девочки без истории в эскорте.\n- Эксклюзивные модели под индивидуальный запрос.\n- Доступ к каталогу с девственницами.\n- Мы двигаем девушек по бюджету за встречу до более комфортного уровня. \n- Полная организация встречи на нас - «консьерж сервис».\n- Возможность оплаты наших услуг из суммы депозита.',
      en: '- Bonus access to all our catalogs: VIP/Exclusive/UAE/Turkey/ALL WEST\n- 8550 profiles (no duplicates)\n- More than 70 media girls and stars: actresses/singers/models/bloggers/athletes/porn actresses.\n- Daily catalog updates.\n- Girls without a history in escorting.\n- Exclusive models for custom requests.\n- Access to the catalog with virgin girls.\n- We move girls to a more comfortable budget level for meetings.\n- Full organization of the meeting is on us - «concierge service».\n- Option to pay for our services from the deposit amount.',
      de: '',
    },
    tag: {
      ru: 'Хит',
      en: 'Hit',
      de: '',
    },
    isBuyed: false,
    isFavorite: false,
  },
  {
    id: 6,
    quantity: 7000,
    price: 100000,
    priceDemo: 0,
    priceSubscription: 0,
    classname: 'camel',
    order: 2,
    preOrder: false,
    lastUpdate: '2023-12-22T11:32:27.181Z',
    countQuestionnaires: 1,
    questionnaires: [
      {
        id: 8,
        avatars: [
          {
            id: 12,
            file: {
              preview:
                '768dff29-6f95-4c78-bc95-b9132af7e82e-06448316-f160-4656-a0cc-c92bd765fd38.jpg',
            },
          },
        ],
      },
    ],
    title: {
      ru: 'VIP',
      en: 'VIP',
      de: '',
    },
    subtitle: {
      ru: 'Малышки только топ-уровня',
      en: 'Only top-level babes',
      de: '',
    },
    description: {
      ru: '- Малышки только топ-уровня.\n- 7000 анкет в каталоге\n- Ежедневное пополнение каталога.\n- Модели по самым популярным направлениям мира.\n- Не работаем с девушками, которые ставят на таймер или работают на поток.\n- Строгая модерация и верификация девочек, проверка анкет по черным спискам элитного эскорта.\n- Возможность заказа люксовых малышек с положительными рекомендациями. \n- Видео проходки, фото, параметры девушек. \n- Модели записывают персональные видео для наших резидентов при необходимости.',
      en: '- Only top-level babes.\n- 7,000 profiles in the catalog.\n- Daily catalog updates.\n- Models from the most popular destinations around the world.\n- Strict moderation and verification of the girls, checking profiles against blacklists of elite escort.\n- Possibility to order luxury babes with positive recommendations.\n- Video walkthroughs, photos, parameters of the girls.\n- Models record personal videos for our residents if necessary.\n- We do not work with girls who charge by the hour or provide mass services.',
      de: '',
    },
    tag: {
      ru: null,
      en: null,
      de: '',
    },
    isBuyed: false,
    isFavorite: false,
  },
  {
    id: 3,
    quantity: 420,
    price: 120000,
    priceDemo: 0,
    priceSubscription: 0,
    classname: 'peach',
    order: 3,
    preOrder: false,
    lastUpdate: '2023-12-22T11:32:27.181Z',
    countQuestionnaires: 1,
    questionnaires: [
      {
        id: 2,
        avatars: [
          {
            id: 4,
            file: {
              preview:
                '326f21fd-26c3-45de-9348-bfce78bc0be7-e6185abf-72e4-4bdf-aa54-8a178d4d5a74.jpg',
            },
          },
        ],
      },
    ],
    title: {
      ru: 'UAE',
      en: 'UAE',
      de: '',
    },
    subtitle: {
      ru: 'Русские и зарубежные малышки модельной внешности на одну ночь',
      en: 'Russian and international babes with model appearance for one night',
      de: '',
    },
    description: {
      ru: '- Русские и зарубежные малышки модельной внешности на одну ночь. \n- 420 анкет в каталоге.\n- Ежедневное пополнение каталога.\n- Только ОАЭ и Арабские страны. \n- Подбор девушек под заданные параметры.\n- Доступна услуга «Консьерж сервис» (оплачивается отдельно).',
      en: '- Russian and international babes with model appearance for one night.\n- 420 profiles in the catalog.\n- Daily catalog updates.\n- Only UAE and Arab countries.\n- Selection of girls according to specified parameters.\n- «Concierge service» available (charged separately).',
      de: '',
    },
    tag: {
      ru: null,
      en: null,
      de: null,
    },
    isBuyed: false,
    isFavorite: true,
  },
  {
    id: 2,
    quantity: 350,
    price: 400000,
    priceDemo: 0,
    priceSubscription: 0,
    classname: 'lemon_chiffon',
    order: 4,
    preOrder: false,
    lastUpdate: '2023-12-22T11:32:27.181Z',
    countQuestionnaires: 1,
    questionnaires: [
      {
        id: 1,
        avatars: [
          {
            id: 1,
            file: {
              preview:
                '326f21fd-26c3-45de-9348-bfce78bc0be7-e6185abf-72e4-4bdf-aa54-8a178d4d5a74.jpg',
            },
          },
        ],
      },
    ],
    title: {
      ru: 'Exclusive + VIP',
      en: 'Exclusive + VIP',
      de: '',
    },
    subtitle: {
      ru: 'Девочки без истории в эскорте',
      en: 'Girls with no history in escorting',
      de: '',
    },
    description: {
      ru: '- Девочки без истории в эскорте.\n- 350 анкет в каталоге.\n- Ежедневное пополнение каталога.\n- Эксклюзивные малышки под индивидуальный запрос.\n- Доступ к каталогу с девственницами.\n- Девушки на разовые встречи и для содержания/отношений.\n- При оплате данного каталога, Каталог VIP предоставляется бонусом.\n- Доступна услуга «Консьерж сервис» (оплачивается отдельно).',
      en: '- Girls with no history in escorting.\n- 350 profiles in the catalog.\n- Daily catalog replenishment.\n- Exclusive babes for custom requests.\n- Access to the catalog with virgin girls.\n- Girls for one-time meetings and for arrangements/relationships.\n- When paying for this catalog, the VIP Catalog is provided as a bonus.\n- «Concierge service» is available (charged separately).',
      de: '',
    },
    tag: {
      ru: null,
      en: null,
      de: null,
    },
    isBuyed: true,
    isFavorite: false,
  },
  {
    id: 5,
    quantity: 670,
    price: 80000,
    priceDemo: 0,
    priceSubscription: 0,
    classname: 'coral',
    order: 5,
    preOrder: false,
    lastUpdate: '2023-12-22T11:32:27.181Z',
    countQuestionnaires: 1,
    questionnaires: [
      {
        id: 7,
        avatars: [
          {
            id: 11,
            file: {
              preview:
                '326f21fd-26c3-45de-9348-bfce78bc0be7-e6185abf-72e4-4bdf-aa54-8a178d4d5a74.jpg',
            },
          },
        ],
      },
    ],
    title: {
      ru: 'ALL WEST',
      en: 'ALL WEST',
      de: '',
    },
    subtitle: {
      ru: 'Топовые малышки в США, Аргентине, Аляске, Канаде, Уругвае',
      en: 'Top girls in the USA, Argentina, Alaska, Canada, Uruguay',
      de: '',
    },
    description: {
      ru: '- Топовые малышки в США, Аргентине, Аляске, Канаде, Уругвае.\n- 670 анкет в каталоге.\n- Ежедневное пополнение каталога.\n- Подбор девушек под заданные параметры.\n- Доступна услуга «Консьерж сервис» (оплачивается отдельно).',
      en: '- Top girls in the USA, Argentina, Alaska, Canada, Uruguay.\n- 670 profiles in the catalog.\n- Daily catalog updates.\n- Selection of girls according to specified parameters.\n- «Concierge service» available (charged separately).',
      de: '',
    },
    tag: {
      ru: null,
      en: null,
      de: null,
    },
    isBuyed: true,
    isFavorite: false,
  },
  {
    id: 4,
    quantity: 110,
    price: 80000,
    priceDemo: 0,
    priceSubscription: 0,
    classname: 'silver',
    order: 6,
    preOrder: false,
    lastUpdate: '2023-12-22T11:32:27.181Z',
    countQuestionnaires: 3,
    questionnaires: [
      {
        id: 4,
        avatars: [
          {
            id: 8,
            file: {
              preview:
                '768dff29-6f95-4c78-bc95-b9132af7e82e-06448316-f160-4656-a0cc-c92bd765fd38.jpg',
            },
          },
        ],
      },
      {
        id: 3,
        avatars: [
          {
            id: 7,
            file: {
              preview:
                '326f21fd-26c3-45de-9348-bfce78bc0be7-e6185abf-72e4-4bdf-aa54-8a178d4d5a74.jpg',
            },
          },
        ],
      },
      {
        id: 5,
        avatars: [
          {
            id: 9,
            file: {
              preview:
                'dd25a9ad-34db-44b1-a116-7c0c2777a43c-e3645f77-4b68-47b4-b4a9-bc146842dcbb.jpg',
            },
          },
        ],
      },
    ],
    title: {
      ru: 'Turkey',
      en: 'Turkey',
      de: '',
    },
    subtitle: {
      ru: 'Выбор девчонок топ внешности во всех регионах Турции',
      en: 'Selection of top-look girls in all regions of Turkey',
      de: '',
    },
    description: {
      ru: '- Выбор девчонок топ внешности во всех регионах Турции.\n- 110 анкет в каталоге.\n- Ежедневное пополнение каталога.\n- Подбор девушек под заданные параметры.\n- Доступна услуга «Консьерж сервис» (оплачивается отдельно).',
      en: '- Selection of top-look girls in all regions of Turkey.\n- 110 profiles in the catalog.\n- Daily catalog replenishment.\n- Selection of girls according to specified parameters.\n- «Concierge service» available (charged separately).',
      de: '',
    },
    tag: {
      ru: null,
      en: null,
      de: null,
    },
    isBuyed: false,
    isFavorite: false,
  },
  {
    id: 7,
    quantity: 39,
    price: 500000,
    priceDemo: 0,
    priceSubscription: 0,
    classname: 'purple',
    order: 7,
    preOrder: true,
    lastUpdate: '2023-12-22T11:32:27.181Z',
    countQuestionnaires: 1,
    questionnaires: [
      {
        id: 9,
        avatars: [
          {
            id: 13,
            file: {
              preview:
                '768dff29-6f95-4c78-bc95-b9132af7e82e-06448316-f160-4656-a0cc-c92bd765fd38.jpg',
            },
          },
        ],
      },
    ],
    title: {
      ru: 'Продажа невинности',
      en: 'Sale of Virginity',
      de: '',
    },
    subtitle: {
      ru: 'Право первой ночи - купить невинность.',
      en: 'Right of the First Night - Purchase Virginity.',
      de: '',
    },
    description: {
      ru: '- Девочки обращаются к нам сами.\n- Россия, СНГ, Евросоюз, большинство девушек доступны для вылета. \n- Каждая модель проходит верификацию на соответствие фото.\n- Видео от девочки для подтверждения по запросу резидента.\n- Все девушки готовы пройти проверку в вашей клинике, либо предоставить справки. \n- Разовые встречи/Содержание на Ваш выбор.',
      en: "- Girls reach out to us directly.\n- Russia, CIS, Europе: most girls are available to fly out to your city.\n- Each model is verified to match her photos.\n- Video confirmations from the girls available upon a resident's request.\n- All girls are ready to undergo a check-up at your clinic or provide certificates.\n- One-time meetings/Maintenance at your discretion.",
      de: '',
    },
    tag: {
      ru: '100% Эксклюзивно',
      en: '100% Exclusive',
      de: '',
    },
    isBuyed: true,
    isFavorite: false,
  },
];

export const AllCatalogsTab = () => {
  return <CatalogsList catalogList={catalogs} />;
};
