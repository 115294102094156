import { Button } from 'shared/ui/Button';
import { ImagesSwitcher } from 'shared/ui/ImagesSwither';
import { Questionnaires } from 'entities/questionnaires/types';
// import { ReactComponent as AddIcon } from 'shared/assets/icons/addsquare.svg';
import { ReactComponent as Edit } from 'shared/assets/icons/edit-sm.svg';
import { ReactComponent as Switch } from 'shared/assets/icons/switch.svg';
import { ReactComponent as Trash } from 'shared/assets/icons/trash2.svg';
import { useNavigate } from 'react-router-dom';
import ROUTES from 'shared/constans/routes';

import styles from './QuestionnairesItem.module.scss';

export const QuestionnairyItem = ({
  id,
  avatars,
  firstName,
  countFavorites,
  isLiked,
  age,
  countLikes,
  isBuyed,
  isFavorite,
  catalog,
}: Questionnaires) => {
  return (
    <div className={styles.item}>
      <ImagesSwitcher id={id} isBuyed={isBuyed} catalog={catalog} images={avatars} isHover={true} />
      <div className={styles.info_container}>
        <span className={styles.info}>
          {firstName}, {age}
        </span>
        <div className={styles.bussines_button_container}>
          <Button
            additionalClasses={styles.bussines_button}
            tag="link"
            href={`${ROUTES.CURRENT_QUESTIONNAIRE}/${id}`}
            type={4}
          >
            <span className="flex align-center gap5">
              <Edit /> Редактировать
            </span>
          </Button>
          <Button onClick={() => {}} additionalClasses={styles.bussines_button} type={4}>
            <span className="flex align-center gap5">
              <Switch />
            </span>
          </Button>
          <Button onClick={() => {}} additionalClasses={styles.bussines_button} type={4}>
            <span className="flex align-center gap5">
              <Trash />
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};
