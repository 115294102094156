import { useForm } from 'shared/hooks/useForm/useForm';
import { FormType } from 'shared/types/FormType/FormType';
import { CatalogQuestionnairesWidget } from 'widgets/Catalogs/CatalogQuestionnairesWidget';
import { CatalogSelectsWidget } from 'widgets/Catalogs/CatalogSelectsWidget';
import { CreateForm } from 'widgets/CreateForm';

export const EditCatalogPage = () => {
  const initialForm: FormType = {
    title: {
      id: 1,
      isRequired: true,
      isValid: false,
      placeholder: 'Название',
      type: 'input',
      value: 'PLATINUM',
      errorText: '',
    },
    subtitle: {
      id: 2,
      isRequired: true,
      isValid: false,
      placeholder: 'Подпись',
      type: 'input',
      value: 'Звезды и медийные',
      errorText: '',
    },
    gradient: {
      id: 3,
      isRequired: true,
      isValid: false,
      placeholder: 'Градиент',
      type: 'select',
      value: '',
      currentOption: 1,
      errorText: '',
      selectOptions: [
        { id: 1, value: 'lemon' },
        { id: 2, value: 'chifon' },
      ],
    },
    price: {
      type: 'input',
      id: 4,
      isValid: false,
      placeholder: 'Стоимость',
      isRequired: true,
      value: '7000',
      errorText: '',
    },
    description: {
      type: 'textarea',
      id: 5,
      placeholder: 'Описание каталога',
      isRequired: false,
      isValid: false,
      errorText: '',
    },
  };
  const { form, handleChangeForm, isValidForm } = useForm(initialForm);

  return (
    <div>
      <CreateForm handleChangeForm={handleChangeForm} form={form} />
      <CatalogSelectsWidget />
      <CatalogQuestionnairesWidget />
    </div>
  );
};
