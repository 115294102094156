import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import { useState } from 'react';
import { DeletePhotoButton } from 'features/questionnaire/DeletePhotoButton';

import styles from './ImageContainer.module.scss';
import { ReactComponent as AddSquareIcon } from '../../../../shared/assets/icons/addsquare.svg';
import { ReactComponent as TrashIcon } from '../../../../shared/assets/icons/trash.svg';
import girlImg from '../../../../shared/assets/mock/mock_girl.png';

export const ImageContainer = () => {
  const [value, setValue] = useState('');
  return (
    <div className={styles.image_container}>
      <div className={styles.image}>
        <img src={girlImg} alt="girl_photo" />
        <div className={styles.delete}>
          <DeletePhotoButton />
        </div>
      </div>
      <Button additionalClasses={styles.add_button} type={4}>
        <AddSquareIcon />
        <span>Добавить Фото анкеты</span>
      </Button>
      <Input
        setValue={setValue}
        value={value}
        tipAsPlaceholder
        additionalClasses={styles.input}
        type={2}
        tip="Телефон (не виден пользователям)"
      />
    </div>
  );
};
