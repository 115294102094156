import { useEffect, useState } from 'react';
import classNames from 'classnames';

import styles from './Tabs.module.scss';
import { TabsProps } from './Tabs.props';

export const Tabs = ({ tabs, className }: TabsProps) => {
  const [activeTab, setActiveTab] = useState(tabs[0].label);
  const [activeTabContent, setActiveTabContent] = useState(tabs[0].content);

  useEffect(() => {
    const tabContent = tabs.find(({ label }) => label === activeTab);
    if (tabContent) {
      setActiveTabContent(tabContent.content);
    }
  }, [activeTab, tabs]);

  return (
    <div className={classNames(styles.tabs, className)}>
      <ul className={styles.list}>
        {tabs.map(({ label, id, isDisabled, isHot }) => (
          <li key={id}>
            <button
              disabled={isDisabled}
              className={classNames(
                styles.button,
                isHot && styles.hot,
                activeTab === label && styles.active_tab
              )}
              onClick={() => setActiveTab(label)}
            >
              {label}
            </button>
          </li>
        ))}
      </ul>
      {activeTabContent}
    </div>
  );
};
