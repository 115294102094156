import { ReactComponent as CrownSVG } from 'shared/assets/icons/crownYellow.svg';

import { Badge } from '../../../../shared/ui/Badge';
import { AuctionCardPropsI, IObjectKeys } from './AuctionCard.props';
import styles from './AuctionCard.module.scss';
import { CommonBankWidget } from '../../../../widgets/Users/CommonBankWidget';
import MockImage from '../../../../shared/assets/mock/Rectangle.png';
import { Transctions } from './components/Transctions';
import { BussinessButtons } from './components/BussinessButtons';

export const AuctionCard = ({
  status,
  price,
  isNew,
  startSum,
  members,
  isAuctionsPage,
  isClosedTab,
}: AuctionCardPropsI) => {
  const DetailsMessageWithStatus = {
    default: 'Стартовая цена:',
    yellow: 'Аукцион закрыт',
    red: 'Аукцион закрыт (Победитель)',
  } as IObjectKeys;

  const operationsList = [
    { date: '2023-12-22T11:32:27.181Z', time: '15:18', sum: 500, id: 1 },
    { date: '2023-12-22T11:32:27.181Z', time: '15:18', sum: 500, id: 2 },
    { date: '2023-12-22T11:32:27.181Z', time: '15:18', sum: 500, id: 3 },
  ];
  return (
    <div className={`${styles.card} ${styles[status]}`}>
      <img className={styles.image} src={MockImage} alt={'girl_photo'} />
      <div className={styles.informations}>
        <p className={styles.title}>
          Продажа девственности
          {isNew && <Badge additionalClasses={styles.badge} type="lemon_chiffon" value="NEW" />}
        </p>
        <span className={styles.description}>
          Lorem ipsum dolor sit amet consectetur. Sed sapien ac tellus ut commodo in porta ultricies
          sit. Dolor ultrices cursus sem viverra fringilla ipsum porta. Viverra viverra fringilla
          adipiscing vitae.
        </span>
        <div className={styles.timer}>
          {isAuctionsPage ? (
            <div className={styles.members_info}>
              <span className={styles.members}>Учавствуют: {members}</span>
              {isClosedTab && <span className={styles.winner}>Победитель: hdhjr_007</span>}
            </div>
          ) : null}
          <div className={styles.header}>
            <p className={`${styles.timer_text} ${styles.text_color}`}>
              {DetailsMessageWithStatus[status]}{' '}
              {status === 'default' && startSum.toLocaleString('ru-RU')}
              {status === 'red' && <CrownSVG className={styles.icon_crown} />}
            </p>
            {status === 'default' && (
              <p className={`${styles.timer_text} ${styles.text_color}`}>01ч 10м 35с</p>
            )}
          </div>
          <span
            style={status !== 'default' || isClosedTab ? { width: '100%' } : {}}
            className={`${styles.progress_bar} ${styles.background}`}
          ></span>
        </div>
      </div>
      <div className={styles.price_container}>
        <CommonBankWidget price={price} color={status} />
        {isAuctionsPage ? (
          <BussinessButtons IsClosedTab={isClosedTab} className={styles.bussiness_buttons} />
        ) : (
          <Transctions className={styles.transactions} operationsList={operationsList} />
        )}
      </div>
    </div>
  );
};
