import { CreateForm } from '../../widgets/CreateForm';
import { useForm } from '../../shared/hooks/useForm/useForm';
import { FormType } from '../../shared/types/FormType/FormType';
import { Button } from '../../shared/ui/Button';
import styles from './CreateAuction.module.scss';
import { ReactComponent as AddIcon } from '../../shared/assets/icons/addsquare.svg';

const CreateAuction = () => {
  const initialForm: FormType = {
    name: {
      type: 'input',
      id: 2,
      isValid: false,
      placeholder: 'Название',
      isRequired: true,
      value: '',
      errorText: '',
    },
    startDate: {
      type: 'calendar',
      id: 0,
      isValid: false,
      placeholder: 'Дата начала',
      isRequired: true,
      value: '',
    },
    endDate: {
      type: 'calendar',
      value: '',
      id: 10,
      isValid: false,
      placeholder: 'Дата окончания',
      isRequired: true,
    },
    bet: {
      type: 'input',
      id: 3,
      isValid: false,
      placeholder: 'Начальная ставка ($)',
      isRequired: true,
      value: '',
      errorText: '',
    },
    description: {
      type: 'textarea',
      id: 4,
      placeholder: 'Описание аукциона',
      isRequired: false,
      isValid: false,
      errorText: '',
      value: '',
    },
  };

  const { form, handleChangeForm, isValidForm } = useForm(initialForm);

  return (
    <div>
      <CreateForm handleChangeForm={handleChangeForm} title="Создание аукциона" form={form} />
      <ul className={styles.list_upload_button}>
        <li className={styles.item}>
          <Button type={6}>
            <AddIcon />
            Добавить фото
          </Button>
        </li>
        <li>
          <Button type={4} additionalClasses={styles.empty_photo}>
            <p></p>
          </Button>
        </li>
        <li>
          <Button type={4} additionalClasses={styles.empty_photo}>
            <p></p>
          </Button>
        </li>
        <li>
          <Button type={4} additionalClasses={styles.empty_photo}>
            <p></p>
          </Button>
        </li>
      </ul>
      <Button disabled={!isValidForm} additionalClasses={styles.button} type={3}>
        Опубликовать
      </Button>
    </div>
  );
};

export default CreateAuction;
