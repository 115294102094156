import { AuctionCard } from '../../../entities/auctions/ui/AuctionCard/AuctionCard';
import styles from './AuctionsList.module.scss';
import { AuctionsListPropsI } from './AuctionsList.props';

export const AuctionsList = ({
  auctions,
  isAuctionPage = false,
  isClosedTab = false,
}: AuctionsListPropsI) => {
  return (
    <ul className={styles.list}>
      {auctions.map(
        ({ price, isNew, startSum, quantity, status, id, isClosed, isWinning, members }) => (
          <li key={id}>
            <AuctionCard
              isAuctionsPage={isAuctionPage}
              price={price}
              isClosedTab={isClosedTab}
              members={members}
              isClosed={isClosed}
              isNew={isNew}
              startSum={startSum}
              status={status}
              quantity={quantity}
              isWinning={isWinning}
            />
          </li>
        )
      )}
    </ul>
  );
};
