import { Modal } from 'shared/ui/Modal';
import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import classNames from 'classnames';

import styles from './UploadModal.module.scss';
import { ReactComponent as AddSquareIcon } from '../../shared/assets/icons/addsquare.svg';
import { UploadlModalPropsI } from './UploadModal.props';
import mockImage from '../../shared/assets/mock/mock3.png';
import { FileList } from './FileList';

const imagesArray = [
  { img: mockImage, id: 1 },
  { img: mockImage, id: 2 },
  { img: mockImage, id: 3 },
];

export const UploadModal = ({
  isVisible,
  close,
  isPutPrice = false,
  title,
  dropzoneText,
}: UploadlModalPropsI) => {
  return (
    <Modal isVisible={isVisible} close={close}>
      <p className={styles.title}>{title}</p>
      <FileList imagesArray={imagesArray} />
      <div className={styles.dropzone_container}>
        <label
          className={classNames(
            styles.dropzone,
            imagesArray.length ? styles.with_images : styles.without_images
          )}
          htmlFor="fileUploader"
        >
          <AddSquareIcon className={styles.icon} />
          <span className={styles.dropzone_text}>{dropzoneText}</span>
          <input
            id="fileUploader"
            className={styles.hidden}
            type="file"
            onClick={(event) => event.stopPropagation()}
            multiple
          />
        </label>
      </div>
      {isPutPrice && (
        <div className={styles.input_container}>
          <Input
            additionalClasses={styles.input}
            tip={'Назначьте цену'}
            tipAsPlaceholder={true}
            value={''}
            type={2}
          />
          <span className={styles.dollar}>$</span>
        </div>
      )}
      <div className={styles.button_container}>
        <Button onClick={close} type={4}>
          Отменить
        </Button>
        <Button type={3}>Добавить</Button>
      </div>
    </Modal>
  );
};
