import styles from './FileList.module.scss';
import { FileListPropsI } from './FileList.props';
import { ReactComponent as TrashIcon } from '../../../shared/assets/icons/trash.svg';

export const FileList = ({ imagesArray }: FileListPropsI) => {
  return imagesArray.length ? (
    <ul className={styles.list}>
      {imagesArray.map(({ img, id }) => (
        <li className={styles.item} key={id}>
          <img className={styles.image} src={img} alt="test" />
          <TrashIcon className={styles.trash_icon} />
        </li>
      ))}
    </ul>
  ) : null;
};
