import { useForm } from 'shared/hooks/useForm/useForm';
import { FormType } from 'shared/types/FormType/FormType';
import { Button } from 'shared/ui/Button';
import { CreateForm } from 'widgets/CreateForm';
import { CalendarCustom } from 'shared/ui/Calendar';
import { useState } from 'react';

import styles from './CreateQuestionnaire.module.scss';

const CreateQuestionnaire = () => {
  type ValuePiece = Date | null;

  type Value = ValuePiece | [ValuePiece, ValuePiece];
  const [value, onChange] = useState<Value>();
  const [value2, onChange2] = useState<Value>();

  //@ts-ignore
  const finishDate = value2?.toISOString().split('T')[0];
  const currentDate = new Date().toISOString().split('T')[0];

  const initialForm: FormType = {
    name: {
      type: 'input',
      id: 2,
      isValid: false,
      placeholder: 'Название',
      isRequired: true,
      value: '',
      errorText: '',
    },
    year: {
      type: 'input',
      currentOption: 0,
      isValid: false,
      placeholder: 'Возраст',
      isRequired: true,
      errorText: '',
      id: 0,
    },
    city: {
      type: 'input',
      currentOption: 0,
      isValid: false,
      placeholder: 'Город',
      isRequired: true,
      errorText: '',
      id: 10,
    },
    height: {
      type: 'input',
      id: 3,
      isValid: false,
      placeholder: 'Рост',
      isRequired: true,
      value: '',
      errorText: '',
    },
    weight: {
      type: 'input',
      id: 4,
      placeholder: 'Вес',
      isRequired: false,
      isValid: false,
      errorText: '',
    },
    breast: {
      type: 'input',
      id: 5,
      placeholder: 'Грудь',
      isRequired: false,
      isValid: false,
      errorText: '',
    },
    type: {
      type: 'input',
      id: 5,
      placeholder: 'Типаж',
      isRequired: false,
      isValid: false,
      errorText: '',
    },
    catalog: {
      type: 'select',
      id: 5,
      placeholder: 'Каталог',
      isRequired: false,
      isValid: false,
      errorText: '',
    },
    youtube: {
      type: 'input',
      id: 5,
      placeholder: 'Ссылка Instagram',
      isRequired: false,
      isValid: false,
      errorText: '',
    },
    instagram: {
      type: 'input',
      id: 5,
      placeholder: 'Ссылка Youtube',
      isRequired: false,
      isValid: false,
      errorText: '',
    },
  };
  const { form, handleChangeForm, isValidForm } = useForm(initialForm);
  return (
    <div>
      <CreateForm form={form} title="Создание анкеты" handleChangeForm={handleChangeForm} />
      <Button disabled={!isValidForm} additionalClasses={styles.button} type={3}>
        Опубликовать
      </Button>
      <div style={{ display: 'flex', gap: 45 }}>
        <CalendarCustom
          title={'Дата начала'}
          maxDate={finishDate}
          date={value}
          setDate={onChange}
        />
        <CalendarCustom
          title={'Дата окончания'}
          minDate={currentDate}
          date={value2}
          setDate={onChange2}
        />
      </div>
    </div>
  );
};

export default CreateQuestionnaire;
