import { useRef, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { BUTTONS_ON_KEYBOARD } from 'shared/constans/buttonOnKeyboard/buttonOnKeyboard';
import { useKeyDownOutside } from 'shared/hooks/useKeyDownOutside/useKeyDownOutside';
import { useOutsideClick } from 'shared/hooks/useOutsideClick/useOutsideClick';

import styles from './Calendar.module.scss';

export function CalendarCustom({ setDate, date, maxDate, title }: any) {
  const [isVisible, setIsVisible] = useState(false);

  const ref = useRef(null);

  const currentDate = new Date().toISOString().split('T')[0];

  useOutsideClick(ref, () => setIsVisible(false), isVisible);
  useKeyDownOutside(ref, () => setIsVisible(false), isVisible, BUTTONS_ON_KEYBOARD.Enter);

  console.log(new Date(maxDate));

  return (
    <div ref={ref} className={styles.container}>
      <button className={styles.button} onClick={() => setIsVisible(!isVisible)}>
        {String(date) ? String(date) : title}
      </button>
      {isVisible && (
        <Calendar
          minDate={new Date(currentDate)}
          className={styles.dropdown}
          onChange={setDate}
          value={date}
          maxDate={new Date(maxDate)}
        />
      )}
    </div>
  );
}
