import { Route, Routes, useLocation } from 'react-router-dom';
import CurrentQuestionnaire from 'pages/CurrentQuestionnaire/CurrentQuestionnaire';
// import ROUTES from 'shared/constans/routes';
import { EditCatalogPage } from 'pages/EditCatalogPage';
import ROUTES from 'shared/constans/routes';
import CreateQuestionnaire from 'pages/CreateQuestionnaire/CreateQuestionnaire';
import { CreateCatalogPage } from 'pages/CreateCatalogPage';
import { useSetPageTitle } from 'shared/hooks/useSetPageTitle';

import Users from '../../../pages/Users/Users';
import AdminPage from '../../../pages/AdminPage/AdminPage';
import { SideBar } from '../../../widgets/SideBar/SideBar';
import { QuickNavigation } from '../../../widgets/QuickNavigation/QuickNavigation';
import Catalogs from '../../../pages/Catalogs/Catalogs';
import Chat from '../../../pages/Chat/Chat';
import Favorites from '../../../pages/Favorites/Favorites';
import Auctions from '../../../pages/Auctions/Auctions';
import { Header } from '../../../widgets/Header';
import { Footer } from '../../../widgets/Footer';
import CurrentUser from '../../../pages/CurrentUser/CurrentUser';
import CreateAuction from '../../../pages/CreateAuction/CreateAuction';

const AppRouter = () => {
  const { pathname } = useLocation();
  console.log(`${ROUTES.AUCTIONS}/createAuction`);
  useSetPageTitle();
  return (
    <div className="wrapper">
      <Header />
      <div style={{ display: 'flex', gap: '55px', marginTop: 25, paddingBottom: 90 }}>
        {pathname !== '/' && <SideBar className="d-block-none600" />}
        {pathname !== '/' && <QuickNavigation />}
        <Routes>
          <Route path={'/'} element={<AdminPage />} />
          <Route path={`${ROUTES.USERS}`} element={<Users />} />
          <Route path={`${ROUTES.USERS}/:id`} element={<CurrentUser />} />
          <Route path={ROUTES.CATALOGS} element={<Catalogs />} />
          <Route path={ROUTES.CHAT} element={<Chat />} />
          <Route path={ROUTES.FAVORITES} element={<Favorites />} />
          <Route path={ROUTES.AUCTIONS} element={<Auctions />} />
          <Route path={`${ROUTES.CREATE_AUCTION}`} element={<CreateAuction />} />
          <Route path={`${ROUTES.CURRENT_QUESTIONNAIRE}/:id`} element={<CurrentQuestionnaire />} />
          <Route path={`${ROUTES.EDIT_CATALOG}/:id`} element={<EditCatalogPage />} />

          <Route path={`${ROUTES.CREATE_CATALOG}`} element={<CreateCatalogPage />} />
          <Route path={`${ROUTES.CREATE_QUESTIONNAIRE}`} element={<CreateQuestionnaire />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default AppRouter;
