import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ROUTES from 'shared/constans/routes';
type RoutesT = typeof ROUTES;
type Keys = keyof RoutesT;
const titles: Record<RoutesT[Keys], string> = {
  [ROUTES.MAIN]: 'Админ панель',
  [ROUTES.AUCTIONS]: 'Аукционы',
  [ROUTES.CATALOGS]: 'Каталоги',
  [ROUTES.CHAT]: 'Чат',
  [ROUTES.CREATE_AUCTION]: 'Создать аукцион',
  [ROUTES.CREATE_CATALOG]: 'Создать каталог',
  [ROUTES.CREATE_QUESTIONNAIRE]: 'Создать анкету',
  [ROUTES.CURRENT_QUESTIONNAIRE]: 'Редактировать анкету',
  [ROUTES.EDIT_CATALOG]: 'Редактировать каталог',
  [ROUTES.FAVORITES]: 'Избранное',
  [ROUTES.LOGIN]: 'Вход',
  [ROUTES.NEWS]: 'Новости',
  [ROUTES.PROFILE]: 'Профиль',
  [ROUTES.RATING]: 'Рейтинг',
  [ROUTES.SELECTION]: 'Подбор',
  [ROUTES.SETTINGS]: 'Настройки',
  [ROUTES.USERS]: 'Пользователи',
};
const pagesKeys = Object.keys(titles);
export const useSetPageTitle = () => {
  const location = useLocation();
  useEffect(() => {
    pagesKeys.forEach((p) => {
      if (location.pathname.includes(p)) {
        document.title = titles[p];
        return;
      }
    });
  }, [location]);
};
