const ROUTES = {
  MAIN: '/',
  USERS: '/users',
  LOGIN: '/login',
  PROFILE: '/profile',
  NEWS: '/news',
  SETTINGS: '/settings',
  CATALOGS: '/catalogs',
  FAVORITES: '/favorites',
  RATING: '/rating',
  SELECTION: '/selection',
  CHAT: '/chat',
  AUCTIONS: '/auctions',
  CREATE_AUCTION: '/auctions/createAuction',
  CREATE_CATALOG: '/create-catalog',
  EDIT_CATALOG: '/edit-catalog',
  CREATE_QUESTIONNAIRE: '/create-questionnaire',
  CURRENT_QUESTIONNAIRE: '/catalogs/edit-questionnaires',
};

export default ROUTES;
