import { AuctionsList } from '../../../../widgets/Auctions/AuctionsList';

export const AuctionsTab = () => {
  const auctions = [
    {
      price: 6500,
      isNew: true,
      startSum: 2000,
      quantity: 15,
      status: 'default',
      id: 0,
      isClosed: false,
      isWinning: false,
    },
    {
      price: 13000,
      isNew: false,
      startSum: 500,
      quantity: 2050,
      status: 'yellow',
      id: 1,
      isClosed: false,
      isWinning: false,
    },
    {
      price: 6500,
      isNew: false,
      startSum: 500,
      quantity: 2050,
      status: 'red',
      id: 2,
      isClosed: false,
      isWinning: false,
    },
  ];
  return <AuctionsList auctions={auctions} />;
};
