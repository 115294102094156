import { Button } from 'shared/ui/Button';
import { useNavigate } from 'react-router-dom';
import { Tabs } from 'shared/ui/Tabs';
import { YouDontSaveModal } from 'widgets/Questionnaires/YouDontSaveModal';
import { UploadModal } from 'widgets/UploadModal';
import { useState } from 'react';

import styles from './CurrentQuestionnaire.module.scss';
import { ReactComponent as BackButtonIcon } from '../../shared/assets/icons/arrowsquareleft.svg';
import { ImageContainer } from './components/ImageContainer';
import { ChangeForm } from './components/ChangeForm';
import { Photos } from './Tabs/Photos';
import { Video } from './Tabs/Video';
import { Hot } from './Tabs/Hot';

const CurrentQuestionnaire = () => {
  const navigate = useNavigate();
  const [isVisibleHotModal, setIsVisibleHotModal] = useState(true);

  const tabs = [
    {
      content: <Photos />,
      label: 'Фото',
      id: 0,
    },
    {
      content: <Video />,
      label: 'Видео',
      id: 1,
    },
    {
      content: <Hot />,
      label: 'HOT 18+',
      isHot: true,
      id: 2,
    },
  ];

  return (
    <div style={{ width: '100%' }}>
      <div className={styles.banner}>
        <div className={styles.banner_left_content}>
          <button onClick={() => navigate(-1)}>
            <BackButtonIcon />
          </button>
          <p className={styles.title}>Редактировать анкету</p>
        </div>
        <Button onClick={() => {}} additionalClasses={styles.button} type={3}>
          <span className={styles.save_button}>Сохранить изменения</span>
        </Button>
      </div>
      <div className={styles.content}>
        <ImageContainer />
        <div className={styles.info_container}>
          <ChangeForm />
          <Tabs className={[styles.tabs]} tabs={tabs} />
        </div>
      </div>
      <YouDontSaveModal />
    </div>
  );
};

export default CurrentQuestionnaire;
