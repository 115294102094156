import { useState, MouseEvent } from 'react';
import { Badge } from 'shared/ui/Badge';
import { Button } from 'shared/ui/Button';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { ReactComponent as EyeIcon } from 'shared/assets/icons/eye.svg';
import ROUTES from 'shared/constans/routes';

import styles from './ImagesSwitcher.module.scss';
import { ImagesSwitherProps } from './ImagesSwitcher.props';

export const ImagesSwitcher = ({
  catalog,
  isBuyed = true,
  images,
  isHover,
  badgeClasses,
  height,
  width,
  id,
}: ImagesSwitherProps) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleButtonClick = (event: MouseEvent, index: number) => {
    event.preventDefault();
    setCurrentImageIndex(index);
  };

  const getRenderContent = () => {
    return (
      <>
        <Badge
          additionalClasses={`${styles.badge} ${badgeClasses || ''}`}
          value={catalog?.title['ru']}
          type={catalog?.classname}
        />
        <img
          className={styles.image}
          height={height || 200}
          width={width || 200}
          src={`https://s3.real-top.sector.show/images/${
            images[currentImageIndex]?.file?.preview || images[currentImageIndex]?.file?.previewBlur
          }`}
          alt="girl"
        />
        <div className={styles.button_container}>
          {images.map((_, index) => (
            <button
              className={classNames(
                styles.button,
                index === currentImageIndex && [styles.button_active]
              )}
              key={index}
              onClick={(event) => handleButtonClick(event, index)}
            ></button>
          ))}
        </div>
        {isHover ? (
          <div className={styles.hover_content}>
            {isBuyed ? (
              <span className="flex align-center gap5">
                <EyeIcon />
                Анкета
              </span>
            ) : (
              <Button additionalClasses={styles.buy_button} type={catalog?.classname}>
                Купить 7000$
              </Button>
            )}
          </div>
        ) : null}
      </>
    );
  };

  return isBuyed ? (
    <Link to={`${ROUTES.CURRENT_QUESTIONNAIRE}/${id}`} className={styles.image_wrapper}>
      {getRenderContent()}
    </Link>
  ) : (
    <div className={styles.image_wrapper}>{getRenderContent()}</div>
  );
};
