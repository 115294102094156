export const getCharacterCount = (value: string) => {
  if (!value.trim()) {
    return 'Поле не может быт пустым';
  }
  if (value.trim().length < 3) {
    return 'Минимальное кол символов: 3';
  }
  if (value.trim().length > 6) {
    return 'Максимальное кол символов: 6';
  }

  return '';
};

export const getDateError = (value: number) => {
  if (!value) {
    return 'Выберите хотя время';
  }
};
