import classNames from 'classnames';
import { MembersModal } from 'widgets/Auctions/MembersModal';
import { useState } from 'react';

import { Button } from '../../../../../../shared/ui/Button';
import styles from './BussinessButtons.module.scss';
import { BussinessButtonsPropsI } from './BussinessButtons.props';

export const BussinessButtons = ({ className, IsClosedTab = false }: BussinessButtonsPropsI) => {
  const [isVisibleMemberModal, setIsVisibleMembersModal] = useState(false);
  return (
    <div className={classNames(styles.container, className && className)}>
      <Button onClick={() => setIsVisibleMembersModal(true)} type={3}>
        Участники и ставки
      </Button>
      <Button type={4}>{IsClosedTab ? 'Повторить аукцион' : 'Редактировать'}</Button>
      <Button type={4}>{IsClosedTab ? 'Удалить' : 'Закрыть аукцион'}</Button>
      <MembersModal
        isVisible={isVisibleMemberModal}
        close={() => setIsVisibleMembersModal(false)}
      />
    </div>
  );
};
